<template>
  <div>
    <b-modal
      id="edit-employee-payment"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`Edit payment for ${user.firstName} ${user.lastName} `"
      size="l"
      hide-footer
    >
      <!-- @close="onCancel" -->
      <b-form>
        <b-form>
          <div style="display: flex;">
            <b-form-group
              id="input-group-8"
              :label="$t('Month')"
              style="width:100%"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="monthh"
                required
                :options="months"
                :reduce="(e) => e.value"
                label="name"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </div>
          <b-form-group
            id="input-group-9"
            :label="$t('Amount')"
            style="width:100%"
            label-for="input-25"
          >
            <b-form-input
              id="input-1"
              v-model="rate"
              type="number"
            />
          </b-form-group>
          <b-form-group
            id="input-group-7"
            :label="$t('Notes')"
            style="width:100%"
            label-for="input-25"
          >
            <b-form-textarea
              id="textarea"
              v-model="textt"
              placeholder="Write here . . . "
              rows="3"
              max-rows="3"
            />
          </b-form-group>
        </b-form>
      </b-form>
      <div style="margin-top: 10px;">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex';
// import moment from 'moment'

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['user', 'feedbackId', 'obj'],
  data() {
    return {
      yearsArray: ['2020', '2021', '2022', '2023'],
      textt: null,
      months: [
        {
          value: 1,
          name: 'January',
        },
        {
          value: 2,
          name: 'February',
        },
        {
          value: 3,
          name: 'March',
        },
        {
          value: 4,
          name: 'April',
        },
        {
          value: 5,
          name: 'May',
        },
        {
          value: 6,
          name: 'June',
        },
        {
          value: 7,
          name: 'July',
        },
        {
          value: 8,
          name: 'August',
        },
        {
          value: 9,
          name: 'September',
        },
        {
          value: 10,
          name: 'October',
        },
        {
          value: 11,
          name: 'November',
        },
        {
          value: 12,
          name: 'December',
        },
      ],
      selectedYear: '2023',
      monthh: 'August',
      rate: 0,
      form: {
        month: null,
        amount: null,
        notes: null,
      },
      backupform: null,

    };
  },
  computed: {

  },
  watch: {
    obj(value) {
      this.text = value.notes
      this.backupform = value
    },
    'obj.month': {
      handler(value) {
        if (value == 1) {
          this.monthh = 'January'
        } else if (value == 2) {
          this.monthh = 'February'
        } else if (value == 3) {
          this.monthh = 'March'
        } else if (value == 4) {
          this.monthh = 'April'
        } else if (value == 5) {
          this.monthh = 'May'
        } else if (value == 6) {
          this.monthh = 'June'
        } else if (value == 7) {
          this.monthh = 'July'
        } else if (value == 8) {
          this.monthh = 'August'
        } else if (value == 9) {
          this.monthh = 'September'
        } else if (value == 10) {
          this.monthh = 'Octomber'
        } else if (value == 11) {
          this.monthh = 'November'
        } else if (value == 12) {
          this.monthh = 'December'
        }
      },
    },
    monthh(value) {
      if (typeof value === 'string') {
        // console.log('value', value)
        const monthObj = this.months.find((month) => { return month.name === value });
        this.form.month = monthObj.value
      } else {
        this.form.month = value
      }
    },

    'obj.amount': {
      handler(value) {
        this.rate = value
      },
      deep: true, // Optional, watches nested properties if set to true
      immediate: true,
    },
    'obj.notes': {
      handler(value) {
        this.textt = value; // Set the text property to pre-fill the textarea
      },
      immediate: true,
    },
    textt(value) {
      this.form.notes = value
    },
    rate(value) {
      this.form.amount = value
    },

  },
  methods: {
    // ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    onSubmit() {
      this.$refs.modal.hide()
      this.$emit('edit', this.form);
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  // grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
