<template>
  <div
    style="margin-top: 0px"
    class="home"
    @click="removeLists"
  >
    <div>
      <b-card no-body>
        <b-tabs
          v-model="tabIndex"
          small
          card
        >
          <b-tab
            :title="$t('EmployeePayment')"
            @click="employeePayment()"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("EmployeePayment") }}</strong>
            </template>
            <!-- <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
              {{ $t("EmployeePayment") }}
            </p> -->
            <div>
              <div
                style="display: flex;"
              >
                <div
                  class="table__header"
                  :style="
                    isactive == true
                      ? 'justify-content: space-between; margin-bottom: -20px;'
                      : 'justify-content: flex-end; margin-bottom: 15px'
                  "
                >
                  <div
                    style="display: flex"
                    class="con"
                  >
                    <div>
                      <div class="black">
                        <b-icon-search
                          v-if="searchUser == ''"
                          class="search1"
                          style="position: absolute; left: 5px; top: 12px"
                        />
                        <b-icon-x-circle
                          v-else-if="searchUser != '' && !noSearch"
                          class="search1"
                          style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                          @click="searchUser = ''"
                        />
                        <input
                          v-model="searchUser"
                          autocomplete="off"
                          type="text"
                          name="search-box"
                          :placeholder="$t('ExchangeEmployeesFullName')"
                          :style="
                            getFilteredActiveUsers.length > 0 ||
                              (getFilteredInactiveUsers.length > 0 && noSearch)
                              ? 'border-radius: 8px 8px 0px 0px'
                              : ''
                          "
                          @keyup="inputChanged"
                          @keydown.down="onArrowDown"
                          @keydown.up="onArrowUp"
                        >
                      </div>
                      <div
                        v-if="
                          (getFilteredActiveUsers.length > 0 || getFilteredInactiveUsers.length > 0) &&
                            noSearch
                        "
                        ref="scrollContainer"
                        class="dropdrop"
                      >
                        <div
                          v-for="(result, index) in getFilteredActiveUsers.length > 0
                            ? getFilteredActiveUsers
                            : getFilteredInactiveUsers"
                          :key="index"
                          ref="options"
                          style="cursor: pointer"
                          class="search-item"
                          :class="{ 'is-active': index === arrowCounter }"
                          @click="
                            searchByOn(result);
                            noSearch = false;
                          "
                        >
                          <p style="margin: 0">
                            {{ result.fullName }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-form-group
                  id="input-group-2"
                  :label="$t('VacationEmployeeT')"
                  label-for="input-2"
                  style="width: 18%;"
                >
                  <b-form-select
                    v-model="employeeType"
                    required
                    :options="employeeTypes"
                    label="text"
                    style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                    :placeholder="$t('Select')"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </div>
              <div
                style="width: 100%; padding-top: 16px"
                class="scroll"
              >
                <table
                  class="team_table"
                  style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px"
                >
                  <thead>
                    <tr>
                      <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                        {{ $t("No.") }}
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("VacationFirstName") }}
                      </th>
                      <th style="padding: 10px; text-align: left;">
                        {{ $t("VacationLastName") }}
                      </th>
                      <th
                        v-if="isactive == true"
                        style="padding: 10px; text-align: left; cursor: pointer"
                        @click="sortByRole"
                      >
                        {{ $t("VacationRole") }}
                        <b-icon-sort-alpha-up-alt v-if="sortedR == false" />
                        <b-icon-sort-alpha-down v-else />
                      </th>
                      <th
                        v-else
                        style="padding: 10px; text-align: left; cursor: pointer"
                        @click="sortByRoleI"
                      >
                        {{ $t("VacationRole") }}
                        <b-icon-sort-alpha-down v-if="sortedRI == false" />
                        <b-icon-sort-alpha-up-alt v-else />
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("VacationEmployeeT") }}
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("EmployeeSalary") }}
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("Filiale") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(user, index) in filteredUsers"
                      :key="index"
                      v-b-tooltip.hover.bottom
                      style="
                      border-bottom: 1px solid #dcdfe6;
                      color: #606266;
                      text-align: left;
                      cursor: pointer;
                    "
                      :title="`Click for ${user.firstName} ${user.lastName} payment details`"
                      @click="matProps(user), showSameId(user.userId) , loadFeedbackk(user)"
                    >
                      <td style="padding: 9px">
                        {{ getTotalItemsForUsers - (page - 1) * pageSize - index }}.
                      </td>
                      <td style="padding: 9px">
                        {{ user.firstName }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.lastName }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.role }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.employeeType }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.salary }}CHF
                      </td>
                      <td style="padding: 9px">
                        {{ user.storeName }}
                      </td>
                    </tr>
                    <div style="margin: 10px;">
                      <b-button
                        v-if="selectedId != null"
                        class="viewAllBtn"
                        @click="showAll"
                      >
                        {{ $t("ViewAll") }}
                      </b-button>
                    </div>
                  </tbody>
                </table>
              </div>
              <div>
                <b-pagination
                  v-if="tabIndex == 0"
                  v-model="page"
                  :total-rows="getTotalItemsForUsers"
                  :per-page="pageSize"
                  first-number
                  last-number
                  :hidden="selectedId"
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <span>{{ $t("Previous") }}</span>
                  </template>
                  <template #next-text>
                    <span>{{ $t("Next") }}</span>
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-tab>
          <b-tab
            :title="$t('MonthlyOverview')"
            @click=" showAll(); monthly()"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("MonthlyOverview") }}</strong>
            </template>
            <!-- <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
              {{ $t("Monthly overview") }}
            </p> -->
            <div>
              <div
                style="display: flex;"
              >
                <b-form-group
                  id="input-group-2"
                  :label="$t('VacationEmployeeT')"
                  label-for="input-2"
                  style="width: 18%;"
                >
                  <b-form-select
                    v-model="employeeType"
                    required
                    style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                    :options="employeeTypes"
                    label="text"
                    :clearable="false"
                    :placeholder="$t('Select')"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
                <div style="display: flex; width: 50%; margin-left: 10px;">
                  <b-form-group
                    id="input-group-8"
                    :label="$t('EditY')"
                    style="width:50%;  margin-right: 10px;"
                    label-for="input-25"
                  >
                    <b-form-select
                      id="input-5"
                      v-model="year"
                      :clearable="false"
                      required
                      style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                      :options="yearsArray"
                      label="text"
                      :placeholder="$t('Select')"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                  <b-form-group
                    id="input-group-8"
                    :label="$t('Month')"
                    style="width:50%"
                    label-for="input-25"
                  >
                    <b-form-select
                      id="input-5"
                      v-model="monthh"
                      :reduce="(e) => e.value"
                      required
                      :options="months"
                      style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                      :clearable="false"
                      label="name"
                      :placeholder="$t('Select')"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                  <div style="display: flex; align-items: center; margin: 0 15px;margin-top: 15px; width: 100%;">
                    <b-form-radio
                      v-model="selection"
                      :value="true"
                    >
                      <span style="margin: 5px">

                        {{ $t("Paid") }}
                      </span>

                    </b-form-radio>
                    <b-form-radio
                      v-model="selection"
                      :value="false"
                      style="margin-left: 10px"
                    >
                      <span
                        style="margin: 5px"
                      >

                        {{ $t("NotPaid") }}
                      </span>

                    </b-form-radio>
                  </div>
                </div>
              </div>
              <div
                style="width: 100%; padding-top: 16px"
                class="scroll"
              >
                <table
                  class="team_table"
                  style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px"
                >
                  <thead>
                    <tr>
                      <!-- <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                        {{ $t("Date") }}
                      </th> -->

                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("VacationFirstName") }}
                      </th>
                      <th style="padding: 10px; text-align: left;">
                        {{ $t("VacationLastName") }}
                      </th>
                      <th
                        v-if="isactive == true"
                        style="padding: 10px; text-align: left; cursor: pointer"
                        @click="sortByRole"
                      >
                        {{ $t("VacationRole") }}
                        <b-icon-sort-alpha-up-alt v-if="sortedR == false" />
                        <b-icon-sort-alpha-down v-else />
                      </th>
                      <th
                        v-else
                        style="padding: 10px; text-align: left; cursor: pointer"
                        @click="sortByRoleI"
                      >
                        {{ $t("VacationRole") }}
                        <b-icon-sort-alpha-down v-if="sortedRI == false" />
                        <b-icon-sort-alpha-up-alt v-else />
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("VacationEmployeeT") }}
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("EmployeeSalary") }}
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("Paid") }}
                      </th>
                      <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                        {{ $t("Filiale") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(user, index) in getMonthlyOverviewPaymentPagination"
                      :key="index"
                      style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: left;
                cursor: pointer;
              "
                    >
                      <!-- <td style="padding: 9px">
                        {{ monthToString(user.month) }} - {{ user.year }}
                      </td> -->
                      <td style="padding: 9px">
                        {{ user.firstName }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.lastName }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.role }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.employeeType }}
                      </td>
                      <td style="padding: 9px">
                        {{ user.salary }}CHF
                      </td>
                      <td style="padding: 9px">
                        {{ user.paidAmount }}CHF
                      </td>
                      <td style="padding: 9px">
                        {{ user.storeName }}
                      </td>
                    </tr>
                    <div style="margin: 10px;">
                      <b-button
                        v-if="selectedId != null"
                        class="viewAllBtn"
                        @click="showAll"
                      >
                        {{ $t("ViewAll") }}
                      </b-button>
                    </div>
                  </tbody>
                </table>
              </div>
              <div>
                <b-pagination
                  v-if="tabIndex == 1"
                  v-model="page"
                  :total-rows="getTotalItemsForUsers_2"
                  :per-page="pageSize"
                  first-number
                  last-number
                  :hidden="selectedId"
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <span>{{ $t("Previous") }}</span>
                  </template>
                  <template #next-text>
                    <span>{{ $t("Next") }}</span>
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'EmployeePaymentListing',
  components: {

  },
  props: {
    users: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userObj: {},
      showOtherComponent: false,
      selection: true,
      tabIndex: 0,
      isactive: true,
      searchUser: '',
      employeeType: 'Internal',
      employeeType2: null,
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      types: [
        {
          value: 'Internal',
          label: this.$t('Internal'),
        },
        {
          value: 'External',
          label: this.$t('External'),
        },
      ],
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      year: moment().format('YYYY'),
      employeeId: null,

      selectedYear: null,
      monthh: moment().format('M'),
    };
  },
  computed: {
    ...mapGetters([
      'getEmployeePaymentPagination',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForUsers_2',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getMonthlyOverviewPaymentPagination',
    ]),
    months() {
      const selectType = [
        {
          value: 1,
          name: `${this.$t('January')}`,
        },
        {
          value: 2,
          name: `${this.$t('February')}`,
        },
        {
          value: 3,
          name: `${this.$t('March')}`,
        },
        {
          value: 4,
          name: `${this.$t('April')}`,
        },
        {
          value: 5,
          name: `${this.$t('May')}`,
        },
        {
          value: 6,
          name: `${this.$t('June')}`,
        },
        {
          value: 7,
          name: `${this.$t('July')}`,
        },
        {
          value: 8,
          name: `${this.$t('August')}`,
        },
        {
          value: 9,
          name: `${this.$t('September')}`,
        },
        {
          value: 10,
          name: `${this.$t('Octomber')}`,
        },
        {
          value: 11,
          name: `${this.$t('November')}`,
        },
        {
          value: 12,
          name: `${this.$t('December')}`,
        },
      ];

      const transformedOptions = selectType.map((option) => {
        return {
          value: option.value,
          text: option.name,
        };
      });

      return [...transformedOptions];
    },
    yearsArray() {
      const selectType = [
        { value: 2020, name: 2020 },
        { value: 2021, name: 2021 },
        { value: 2022, name: 2022 },
        { value: 2023, name: 2023 },
      ];

      const transformedOptions = selectType.map((option) => {
        return {
          value: option.value,
          text: option.name,
        };
      });

      return [...transformedOptions];
    },
    employeeTypes() {
      const selectType = [
        {
          value: 'Internal',
          name: this.$t('Internal'),
        },
        {
          value: 'External',
          name: this.$t('External'),
        },
      ];

      const transformedOptions = selectType.map((option) => {
        return {
          value: option.value,
          text: option.name,
        };
      });

      return [...transformedOptions];
    },
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getEmployeePaymentPagination.filter((getEmployeePaymentPagination) => {
          return getEmployeePaymentPagination.userId === this.selectedId;
        })
        : this.getEmployeePaymentPagination;
    },
  },
  watch: {
    page(value) {
      if (this.tabIndex == 0) {
        this.loadEmployeePaymentPagination({
          firstName: this.searchUser == '' ? null : this.searchUser,
          employeeType: this.employeeType ? null : this.employeeType.value,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      } else {
        this.loadMonthlyOverviewPaymentPagination({
          year: this.year,
          month: this.monthh ? moment().format('MM') : this.monthh.value,
          employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
          filterPaidEmployees: this.selection,
          pageNumber: value,
          pageSize: 15,
        })
      }
    },
    year(value) {
      this.loadMonthlyOverviewPaymentPagination({
        year: value,
        month: this.monthh ? moment().format('MM') : this.monthh.value,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: this.selection,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    searchUser(value) {
      this.selectedId = null;
      if (value == '') {
        this.removeLists();
        if (this.isactive) {
          this.loadEmployeePaymentPagination({
            firstName: value || null,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value || null,
          });
        }
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else if (this.isactive) {
        this.filterActiveUsersByName(value);
      } else {
        this.filterInactiveUsersByName(value);
      }
      this.noSearch = true;
    },
    employeeType(value) {
      if (this.tabIndex == 0) {
        this.loadEmployeePaymentPagination({
          firstName: this.searchUser == '' ? null : this.searchUser,
          employeeType: value || null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        });
      } else {
        const temp = this.monthh ? moment().format('MM') : this.monthh.value;
        this.loadMonthlyOverviewPaymentPagination({
          year: this.year,
          month: temp,
          employeeType: value || null,
          filterPaidEmployees: this.selection,
          pageNumber: 1,
          pageSize: 15,
        })
      }
    },
    monthh(value) {
      this.loadMonthlyOverviewPaymentPagination({
        year: this.year,
        month: value,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: true,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    selection(value) {
      const temp = this.monthh ? moment().format('MM') : this.monthh.value;
      this.loadMonthlyOverviewPaymentPagination({
        year: this.year,
        month: temp,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: value,
        pageNumber: 1,
        pageSize: 15,
      })
      // this.loadMonthlyOverviewPaymentPagination({
      //   year: this.year,
      //   month: this.monthh == '' ? moment().format('MM') : this.monthh.value,
      //   employeeType: this.employeeType ? this.employeeType.value : null,
      //   filterPaidEmployees: value,
      //   pageNumber: 1,
      //   pageSize: 15,
      // })
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions([
      'loadEmployeePaymentPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'loadPayments',
      'loadEmployeePaymentPagination',
      'loadMonthlyOverviewPaymentPagination',
    ]),
    monthly() {
      this.page = 1
      const temp = moment().format('MM')
      this.loadMonthlyOverviewPaymentPagination({
        year: moment().format('YYYY'),
        month: temp,
        employeeType: 'Internal',
        filterPaidEmployees: true,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    employeePayment() {
      this.page = 1
      this.loadEmployeePaymentPagination({
        firstName: null,
        lastName: null,
        employeeType: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);

        await this.loadEmployeePaymentPagination({
          firstName: null,
          lastName: null,
          employeeType: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.userId;
      this.$emit('show-the-component');
    },
    loadRemainingDays(value) {
      const currentYear = new Date().getFullYear();
      this.remainingDaysOfEmployee({ employeeUserId: value, year: currentYear });
    },
    monthToString(value) {
      if (value == 1) {
        return 'Janar'
      } if (value == 2) {
        return 'February'
      } if (value == 3) {
        return 'March'
      } if (value == 4) {
        return 'April'
      } if (value == 5) {
        return 'May'
      } if (value == 6) {
        return 'June'
      } if (value == 7) {
        return 'July'
      } if (value == 8) {
        return 'August'
      } if (value == 9) {
        return 'September'
      } if (value == 10) {
        return 'Octomber'
      } if (value == 11) {
        return 'November'
      } if (value == 12) {
        return 'December'
      }
      return ''
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.loadEmployeePaymentPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadFeedbackk(id) {
      // console.log('testt', id.userId)
      const currentYear = new Date().getFullYear();
      this.loadPayments({ userId: id.userId, year: currentYear })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      // console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getEmployeePaymentPagination.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getEmployeePaymentPagination.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    nameToShow(value) {
      if (value > 0) {
        return value
      }
      return '0'
    },
    async searchByOn(value) {
      this.searchUser = value.fullName;
      // console.log('123', value.firstName)
      this.noSearch = false;
      await this.loadEmployeePaymentPagination({
        firstName: value.firstName,
        lastName: value.lastName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>
.button {
  width: 195px;
}

.viewAllBtn{
  background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;
}
.addEmployeeButton {
  width: 210px;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
